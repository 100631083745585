<template>
  <div>
    <template v-if="!isMobile">
      <div class="manager-statistic">
        <template v-for="item in getStatisticList">
          <div
            v-if="isShowItem(item.value)"
            :key="item.id"
            class="manager-statistic__item"
          >
            <div class="manager-statistic__title">
              {{ capitalize(item.title) }}
            </div>
            <div class="manager-statistic__value">- {{ item.value }}</div>
          </div>
        </template>
        <div class="manager-statistic__wrap-btn-auto">
          <el-tooltip
            :content="'Заполните параметр “Дата с“'"
            :disabled="enableExcel"
            placement="top"
          >
            <IqButton
              v-if="showExcelButton"
              style="width: auto"
              size="mini"
              color="primary"
              :disabled="!enableExcel"
              @onClick="handleExcelReport"
            >
              Выгрузить отчет в Excel
            </IqButton>
          </el-tooltip>

          <IqButton
            v-if="!isViewer && canCreateManualsTs"
            style="width: auto"
            size="mini"
            icon="TruckIcon"
            icon-height="28px"
            icon-width="28px"
            @onClick="handleAddAuto"
          >
            Добавить авто
          </IqButton>

          <IqButton
            v-if="showDeleteButton"
            style="width: auto"
            size="mini"
            :disabled="!enableDeleting"
            @onClick="handleMassDelete"
          >
            Удалить выбранные ТС
          </IqButton>

          <IqButton
            v-if="isViewer"
            style="width: auto"
            size="mini"
            icon="IconSettings"
            icon-height="28px"
            icon-width="28px"
            @onClick="handleChangeTable"
          >
            Изменить таблицу
          </IqButton>
        </div>
      </div>
      <div class="manager-statistic mod-limit">
        <div>{{ manualsInfoText.text }}</div>
        <div v-if="manualsInfoText.quantity" class="manager-statistic__value">
          {{ manualsInfoText.quantity }}
        </div>
      </div>
    </template>
    <template v-else>
      <div style="display: flex; align-items: center; flex-wrap: wrap">
        <template v-for="item in getStatisticList">
          <div
            v-if="isShowItem(item.value)"
            :key="item.id"
            class="manager-statistic__item"
          >
            <div class="manager-statistic__title">
              {{ capitalize(item.title) }}:
            </div>
            <div class="manager-statistic__value">{{ item.value }}</div>
          </div>
        </template>
        <div class="manager-statistic__item">
          <div class="manager-statistic__title">{{ manualsInfoText.text }}</div>
          <div v-if="manualsInfoText.quantity" class="manager-statistic__value">
            {{ manualsInfoText.quantity }}
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { GET_DAY } from '@/constants/date'
import { GET_IS_ALLOWED_MANUAL_TS, GET_IS_MOBILE } from '@/store/actions'
import { GET_MANUALS_INFO } from '@/views/manager/store/actions'
import { capitalize, getTime, now } from '@/core'
import { mapGetters } from 'vuex'
import { timeslotStatuses } from '@/views/manager/data/manager'
import IqButton from '../../../ui/components/buttons/IqButton'

export default {
  name: 'ManagerStatistic',
  components: { IqButton },

  props: {
    statistic: {
      type: Object,
      default: () => ({}),
    },
    enableExcel: {
      type: Boolean,
      default: false,
    },
    enableDeleting: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters({
      isMobile: GET_IS_MOBILE,
      canCreateManualsTs: GET_IS_ALLOWED_MANUAL_TS,
      manualsInfo: GET_MANUALS_INFO,
    }),
    getStatisticList() {
      return Object.entries(this.statistic).map(([key, value], id) => ({
        title: timeslotStatuses[key].title,
        value,
        id,
      }))
    },
    getDate() {
      return {
        day: getTime(now(), GET_DAY),
      }
    },
    manualsInfoText() {
      if (!this.canCreateManualsTs) {
        return {
          text: 'Лимит ручных таймслотов: отключен',
          quantity: null,
        }
      }
      if (!this.manualsInfo.manuals_enabled) {
        return {
          text: 'Нет лимита на ручные ТС',
          quantity: null,
        }
      }
      if (this.manualsInfo.manuals_used >= this.manualsInfo.manuals_limit) {
        return {
          text: 'Лимит ручных ТС исчерпан:',
          quantity: '0/' + this.manualsInfo.manuals_limit,
        }
      }

      return {
        text: `Остаток ручных ТС на ${this.getDate.day}:`,
        quantity: `${
          this.manualsInfo.manuals_limit - this.manualsInfo.manuals_used
        }/${this.manualsInfo.manuals_limit}`,
      }
    },
    showExcelButton() {
      return (
        this.isSuperAdmin || this.isAdmin || this.isViewer || this.isManager
      )
    },
    showDeleteButton() {
      return this.isSuperAdmin || this.isAdmin
    },
  },
  methods: {
    capitalize,
    isShowItem(val) {
      return val !== null
    },
    handleAddAuto() {
      this.$emit('addAuto')
    },
    handleChangeTable() {
      this.$emit('changeManagerTable')
    },
    handleExcelReport() {
      this.$emit('createExcelReport')
    },
    handleMassDelete() {
      this.$emit('onMassDelete')
    },
  },
}
</script>

<style lang="sass">
.manager-statistic
  padding: 20px 28px 10px
  display: flex
  align-items: center
  &__item
    display: flex
    justify-content: flex-start
    align-items: center
    margin-bottom: 8px
    & + &
      margin-right: 20px
  &__value
    color: $grey-font
    font-family: Roboto, sans-serif
    margin-left: 6px
    font-weight: 700
    font-size: 14px
  &__title
    color: $grey-font
    font-family: Roboto, sans-serif
    margin-left: 6px
    font-weight: 400
    font-size: 14px

  &__wrap-btn-auto
    display: flex
    justify-content: flex-end
    align-items: center
    margin-left: 20px
    flex-grow: 1
  &.mod-limit
    padding-top: 0
    padding-bottom: 20px
</style>
